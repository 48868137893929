.underline--magical {
    background-image: linear-gradient(120deg, #84fab0 0%, #8fd3f4 100%);
    background-repeat: no-repeat;
    background-size: 100% 0.2em;
    background-position: 0 88%;
    transition: background-size 0.25s ease-in;
}

.underline--magical:hover {
    background-size: 100% 88%;
}

.section_title {
    text-decoration: underline #32557f dashed;
    text-underline-position: under;
}