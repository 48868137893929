@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap');

html {
    scroll-padding-top: 100px;
}

.blob {
    animation: move 4s infinite;
    animation-delay: 2s;
}

* {
    font-family: 'Poppins', sans-serif;
    scroll-behavior: smooth;
}

::-webkit-scrollbar {
    width: 10px;
}

::-webkit-scrollbar-track {
    background: #f8eded;
}

::-webkit-scrollbar-thumb {
    background: #6e6e6e;
}

::-webkit-scrollbar-thumb:hover {
    background: #6e6e6e;
}


@keyframes move {
    0% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
    }

    70% {
        transform: scale(1);
        box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
    }

    100% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    }

}

.main {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-family: sans-serif;
    flex-wrap: wrap;
}

.card {

    position: relative;
    width: 400px;
    height: 650px;
    box-shadow: 0 0 13px 0px rgba(0, 0, 0, .3);
    transition: .5s;
    overflow: hidden;
    margin: 1.15rem;
}

.card img {
    width: 400px;
    height: 250px;
    transition: 2.5s;
}

.card .card-body {
    padding: .5rem .85rem 1rem;
    height: 0;
}

.card .card-body h6 {
    margin: .5rem 0;
    font-size: 1rem;
    font-weight: 600;
    color: crimson;
    letter-spacing: 3px;
    font-family: 'Poppins', sans-serif;
}

.card .card-body p {
    text-align: justify;
    font-size: 1rem;
    font-weight: 550;
    line-height: 25px;
    font-family: 'Poppins', sans-serif;
}

.card .card-body div {
    font-family: 'Poppins', sans-serif;
    text-align: right;
    width: 100%;
}

.card .card-body div a {
    position: relative;
    text-decoration: none;
    color: #526ffd;
    padding: .5rem;
    text-align: center;
    z-index: 999;
    font-size: .85rem;
}

.card .card-body div a::before {
    position: absolute;
    content: '';
    width: 13px;
    height: 13px;
    background: #526ffd;
    border-radius: 300px;
    left: -13px;
    top: 3px;
    z-index: -1;
    transition: 2.3s;
}

.card .card-body div a:hover {
    color: #fff;
}

.card .card-body div a:hover::before {
    width: 100%;
    height: 88%;
    left: 0;
}

@media screen and (max-width: 768px) {
    .card {
        width: 100%;
        height: 670px;
        margin-top: 25px;
    }

    .card img {
        width: 100%;
        height: 220px;
    }

    .card .card-body {
        padding: 1.5rem .85rem 1rem;
        height: 0;
    }

    .card .card-body h6 {
        margin: .5rem 0;
        font-size: 1rem;
        font-weight: 600;
        color: crimson;
        letter-spacing: 3px;
        font-family: 'Poppins', sans-serif;
    }

    .card .card-body p {
        text-align: justify;
        font-size: 1rem;
        line-height: 25px;
        font-family: 'Poppins', sans-serif;
    }

    .card .card-body div {
        font-family: 'Poppins', sans-serif;
        text-align: right;
        width: 100%;
    }

    .card .card-body div a {
        position: relative;
        text-decoration: none;
        color: #526ffd;
        padding: .5rem;
        text-align: center;
        z-index: 999;
        font-size: .85rem;
    }

    .card .card-body div a::before {
        position: absolute;
        content: '';
        width: 13px;
        height: 13px;
        background: #526ffd;
        border-radius: 300px;
        left: -13px;
        top: 3px;
        z-index: -1;
        transition: 2.3s;
    }

    .card .card-body div a:hover {
        color: #fff;
    }

    .card .card-body div a:hover::before {
        width: 100%;
        height: 88%;
        left: 0;
    }
}

button.scroll-to-top {
    display: none;
    position: fixed;
    bottom: 20px;
    right: 30px;
    z-index: 99;
    border: none;
    outline: none;
    background-color: #D97743;
    color: white;
    cursor: pointer;
    padding: 15px;
    border-radius: 10px;
    font-size: 18px;
}


.heading {
    padding: 30px 0 8px;
    position: relative;
}

.heading::before {
    content: "";
    position: absolute;
    left: 50%;
    bottom: 0px;
    width: 200px;
    height: 8px;
    transform: skew(-12deg) translateX(-50%);
    background: #D97743;
    z-index: -1;
}

.heading_home {
    padding: 30px 0 8px;
    position: relative;
}

.heading_home::before {
    content: "";
    position: absolute;
    left: 45%;
    bottom: 0px;
    width: 300px;
    height: 14px;
    transform: skew(-12deg) translateX(-50%);
    background: #8DBF66;
    z-index: -1;
}