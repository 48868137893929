@media screen and (max-width: 600px) {
    .container_board {
        color: #000;
        display: grid;
        grid-template-columns: 1fr;
        gap: 16px;
    }

    .card-container_board {
        width: 300px;
        height: 435px;
        background-color: #fff;
        border-radius: 8px;
        box-shadow: 0 2px 20px rgba(0, 0, 0, 0.1);
        overflow: hidden;
        padding: 10px;
    }
}

@media screen and (min-width: 600px) {
    .container_board {
        color: #000;
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 10px;
    }

    .card-container_board {
        width: 300px;
        height: 435px;
        background-color: #fff;
        border-radius: 8px;
        box-shadow: 0 2px 20px rgba(0, 0, 0, 0.1);
        overflow: hidden;
        padding: 10px;
    }
}

@media only screen and (min-width: 1200px) {
    .card-container_board {
        width: 300px;
        height: 435px;
        background-color: #fff;
        border-radius: 8px;
        box-shadow: 0 2px 20px rgba(0, 0, 0, 0.1);
        overflow: hidden;
        padding: 10px;
    }

    .container_board {
        color: #000;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        gap: 16px;
    }
}