@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap');

.main_services {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-family: sans-serif;
    flex-wrap: wrap;
    margin-left: auto;
    margin-right: auto;
}

.card_services {
    border-radius: 5px;
    position: relative;
    width: 300px;
    min-height: 460px;
    box-shadow: 0 0 13px 0px rgba(0, 0, 0, .3);
    transition: .5s;
    overflow: hidden;
    margin: 1.15rem;
    background-color: white;
}

.card_services img {
    width: 400px;
    height: 200px;
    transition: 2.5s;
}

.card_services .card_services-body {
    padding: .5rem 1rem 1rem;
    height: 0;
}

.card_services .card_services-body h6 {
    margin: .5rem 0;
    font-size: 1rem;
    font-weight: 600;
    color: #0E1F51;
    letter-spacing: 3px;
    font-family: 'Poppins', sans-serif;
}

.card_services .card_services-body p {
    text-align: justify;
    font-size: 1rem;
    font-weight: 550;
    line-height: 25px;
    font-family: 'Poppins', sans-serif;
    color: #242424;
}

.card_services .card_services-body div {
    font-family: 'Poppins', sans-serif;
    text-align: right;
    width: 100%;
}

@media screen and (max-width: 600px) {
    .main_services {
        width: 100%;
    }

    .card_services {
        width: 100%;
        min-height: 450px;
        margin-top: 25px;
    }

    .card_services img {
        width: 100%;
        height: 220px;
    }

    .card_services .card_services-body {
        padding: 1.5rem .85rem 1rem;
        height: 0;
    }

    .card_services .card_services-body h6 {
        margin: .5rem 0;
        font-size: 1rem;
        font-weight: 600;
        color: black;
        letter-spacing: 3px;
        font-family: 'Poppins', sans-serif;
    }

    .card_services .card_services-body p {
        text-align: justify;
        font-size: 1rem;
        line-height: 25px;
        font-family: 'Poppins', sans-serif;
    }

    .card_services .card_services-body div {
        font-family: 'Poppins', sans-serif;
        text-align: right;
        width: 100%;
    }

}

@media screen and (min-width: 600px) {
    .main_services {
        width: 100%;
    }
}